import React from 'react'

export const AppContext = React.createContext(null)

export function AppProvider({children}) {

   
    const [clientt, setClientt] = React.useState("")
    const [site, setSite] = React.useState("")
    const [clients, setClients] = React.useState([])
    const [sites, setSites] = React.useState([])
    const [urls, setUrls] = React.useState([])
    const [emails, setEmails] = React.useState([])
    const [page, setPage] = React.useState("login")
    const [token, setToken] = React.useState("")
    const [pages, setsousPages] = React.useState("urls")
    const [update, setUpdate] = React.useState(false)
    const [role, setRole] = React.useState("Viewer")
    const [addJour,setAddJour]=React.useState(false)
    const [addMen,setAddMen]=React.useState(false)
    const [addHeb,setAddHeb]=React.useState(false)

    const [client, setClientName] = React.useState("")
    const [id_client, setIdClient] = React.useState("")
    const [site_name, setSiteName] = React.useState("")
    const [id_site, setIdSite] = React.useState("")
    const [url, setUrl] = React.useState("")
    const [sending_frequency, setSendingFrequency] = React.useState("journalier")
    const [page_format, setPageFormat] = React.useState("a3")
    const [order_, setOrder] = React.useState(1)
    const [is_active, setIsActive] = React.useState(1)
    const [auto_mode, setAutoMode] = React.useState(1)
    const [listVideJour,setListVideJour]=React.useState(false)
    const [listVideHeb,setListVideHeb]=React.useState(false)
    const [listVideMen,setListVideMen]=React.useState(false)
    const [listUrls,setListUrls]=React.useState([])
    const [frequence,setFrequence]=React.useState("")
    const [listFre,setListFre]=React.useState([])
    const [result,setResult]=React.useState([])
    const [listRapprt,setListRapprt]=React.useState([])
    const [options,setOptions]=React.useState([])
    const [infoRapports,setInfoRapports]=React.useState([])
    const [manque,setManque]=React.useState([])
    const [quitter,setQuitter]=React.useState(false)

    React.useEffect(() => {
        setRole(localStorage.getItem("role"))
    },[token])

    function addClientSite(newClientSite){
        let listClient=clients
        let listSite=sites 
        let c={client:newClientSite.client,id_client:newClientSite.id_client}
        let s={site_name:newClientSite.site_name,id_site:newClientSite.id_site}
        listClient=c
        listSite=s
        clients.push(listClient)
        sites.push(listSite)
        // console.log({clients})
        // console.log({sites})
      
        setUpdate(true)
    }

    function addEmails(newEmail){
        let listEmail=emails 
        listEmail=newEmail
       
        setEmails(listEmail)
        setUpdate(true)
    }
    

    function addUrls(newUrl){
        let listUrl=urls 
        listUrl=newUrl
       
        urls.push(listUrl)
        setUpdate(true)
        
        
    }

    function addSite(newSite){
        let listSite=sites 
         listSite.push(newSite)
        // console.log({newSite})
    //    console.log({listSite})
        setSites(listSite)
        // console.log({sites})
        setUpdate(true)
        
        
    }
    

    // React.useEffect(() => {
    //    console.log("context",{clients})
      
    // }, [clientt])

   
   
     

   

    return (
        <AppContext.Provider value={{
           

            clientt, setClientt,
            site, setSite,
            page,setPage,
            token,setToken,
            pages,setsousPages,
            update,setUpdate,
            clients,setClients,
            sites,setSites,
            urls,setUrls,
            emails,setEmails,
            role,setRole,
            addEmails,
            addUrls,
            addSite,
            addClientSite,
            addJour,setAddJour,
            addMen,setAddMen,
            addHeb,setAddHeb,

            client,setClientName,
            id_client,setIdClient,
            site_name,setSiteName,
            id_site,setIdSite,
            url,setUrl,
            sending_frequency,setSendingFrequency,
            page_format,setPageFormat,
            auto_mode,setAutoMode,
            is_active,setIsActive,
            order_,setOrder,
            listVideJour,setListVideJour,
            listVideHeb,setListVideHeb,
            listVideMen,setListVideMen,
            listUrls,setListUrls,
            manque,setManque,
            frequence,setFrequence,
            listFre,setListFre,
            options,setOptions,
            infoRapports,setInfoRapports,
            result,setResult,
            listRapprt,setListRapprt,
            quitter,setQuitter,


        }}>
            {children}
        </AppContext.Provider>
    )
}
