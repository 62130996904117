import React,{useContext, useState,useEffect} from 'react'
import './urls.scss'
import plus from '/assets/plus.jpg'
import moins from '/assets/moins.png'
import iconeModif from '/assets/iconeModif.png'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'   




export function AjoutUrl (){
    const {manque,setsousPages,token,addUrls,clientt,site,setAddJour,setAddHeb,setAddMen}=useContext(AppContext)
    const [sending_frequency,setSendingFrequency]=useState(manque[0]||"")
    const [urls,setUrls]=React.useState([{url:"",
    id_site:"",
    id_client:"",
    sending_frequency:"",
    page_format:"",
    is_active:1,
    auto_mode:1,
    order_:1}])

    const [update,setUpdate]=React.useState(false)

    


    useEffect(() => {
        if(urls[0]){
            // console.log(urls)
        }
    },[urls])

    const addNewUrl=()=>{
       const urlTemp= {url:"",
       id_site:"",
       id_client:"",
       sending_frequency:"",
       page_format:"",
       is_active:1,
       auto_mode:1,
       order_:1}
       
       let urlsTemp=urls
       urlsTemp.push(urlTemp)
       setUrls(urlsTemp)
        setUpdate(!update)
    }


    const removeNewUrl=()=>{
        let list=urls
        setUpdate(!update)
        list.pop()
        setUrls(list)
        urls.length==0?setsousPages("urls"):null
    }

    const enregistrer = ()=>{
        
        // console.log({urls})
        postier.ajoutUrls(token,urls,setUpdate,setsousPages,setAddJour,setAddHeb,setAddMen,setAddHeb,setAddMen)
        addUrls(urls)
        postier.getUrls(token,clientt.id_client,site.id_site,setUrls)
        // setListUrls([])
     }
 
   



    return(<div className="ajoutPushMail">

        <label className='frequence'>Fréquence d'envoie</label>
            <select className="selectUpdate" defaultValue={sending_frequency} onChange={e=>setSendingFrequency(e.target.value)} >
            {manque.map(opt=>
            <option value={opt} >{opt}</option>
            )}
            </select>
                
          <button className='boutonModifUrl'  
          onClick={addNewUrl} ><img src={plus} alt='plus' className='iconeModif'/></button>

        <button className='boutonModifUrl'  
             onClick={removeNewUrl} ><img src={moins} alt='moins' className='iconeModif'/></button>
             <br/><br/>
           
              {urls.map((u,i)=><NewUrl u={u} setUrls={setUrls} index={i} setUpdate={setUpdate} sending_frequency={sending_frequency} order_={i+1}/>)}
   
              <button className="boutonEnregistrer" onClick={enregistrer}>Enregistrer</button>
                <button className="boutonEnregistrer" onClick={()=>setsousPages("urls")}>Annuler</button>
              
                <br/><br/>

        </div>)

}





 function NewUrl({u,setUrls,index,setUpdate,sending_frequency,order_}) {
    const optionsPage=["a3","a4"]
    const optionsMode=["Automatique","Manuel"]

     const {clientt,site}= useContext(AppContext)
     const [url,setUrl] =useState(u.url)
     const [page_format,setPageFormat] =useState(optionsPage[1])
    //  const [order_,setOrder] =useState(u.order_)
     const [is_active,setIsActive] =useState(u.is_active)
     const [auto_mode,setAutoMode] =useState(u.auto_mode)
     

    

    useEffect(() => {
        setUrls(urls=>{
            let us=urls
            us[index]={url,page_format,order_,auto_mode,is_active,sending_frequency,id_client:clientt.id_client,id_site:site.id_site}
            setUpdate(u=>!u)
            
          //  console.log(us[index])
            return us
        })
        // console.log(u.url)
    },[url,page_format,order_,is_active,auto_mode])


    return (
        <div>
        <label className="labelAdd">Url</label>
        <input className="inputUrl" value={url} onChange={e=>setUrl(e.target.value)}/>
        <br/>
        <label className="labelAdd">Format</label>
        <select className='formatAdd' defaultValue={page_format} onChange={e=>setPageFormat(e.target.value)}>
        {optionsPage.map(opt=>
                <option value={opt} >{opt}</option>
                )}

        </select>
        <label className="labelAdd">Numéro de page</label>
        <input className="inputOrderAdd" defaultValue={order_} onChange={e=>setOrder(e.target.value)}/>
        <br/>
        <label className="labelAdd">Is_active</label>
        <input className="checkbox" type="checkbox" defaultChecked={is_active} onChange={()=>{{
                setIsActive(!is_active)}
                is_active==false?setIsActive(1):setIsActive(0)}}/>

       
       
        <label className="labelAdd">Mode</label>
        <select className="selectModeAdd" defaultValue={auto_mode} onChange={e=>{setAutoMode(e.target.value)
               auto_mode=="Automatique"?setAutoMode(1):setAutoMode(0)}}>
                {optionsMode.map(opt=>
                <option value={opt} >{opt}</option>
                )}
                </select>

                <div className="separateur"></div>
           
</div>

    )}



   
     
       

  