import axios from 'axios'
import 'regenerator-runtime/runtime'

const messages = {
    erreur: "connexion impossible"
}

class Postier{

    async getSites(token,client,setSites){
        // console.log({id_client:client.id_client})
        // console.log(`/api-v0/sites/${client.id_client}`)
       axios.get(`/api-v0/sites/${client.id_client}`,{headers:{ Authorization: `Bearer ${token}` }})
           .then(res=>{
               // console.log('site postier',res.data)
               // console.log('type de site postier',typeof res.data)
               setSites(res.data)
               // console.log("site pos",res.data)
       }).catch(err=>{
           alert("La liste des sites ne peut pas être récupérée")
       })

   }

   
    ajoutSite(token,data,setIsModified){

        //console.log("pos",{data})
        axios.post(`api-v0/site/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
                    alert("Ajout réussi!!")
                    setIsModified(false)
                   // console.log("postier",res)
              
        }).catch(err=>{
            alert("Le site ne peut pas étre ajouté")
        })
    
    }
 
}



export const postier = new Postier()