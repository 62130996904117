import axios from 'axios'
import 'regenerator-runtime/runtime'



class Postier{
    async getUrls(token,id_client,id_site,setListUrls,listFre,setOptions,setUrls,setFrequence){
        // console.log({token})
        // console.log(`/api-v0/urls/${id_client}/${id_site}`)
        axios.get(`/api-v0/urls/${id_client}/${id_site}`,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
                // const urls=res.data
                // console.log('type urles postier',typeof urls,{urls})
              let list=res.data
              let listJour= []
              let listHeb=[]
              let listMen=[]
                setListUrls(res.data)
                // console.log({pot:res.data})
                for (const l of list) {

                    if(l.sending_frequency=="journalier"){
                        listJour.push(l)
                    }
                    if(l.sending_frequency=="hebdomadaire"){
                        listHeb.push(l)
                    }
                    if(l.sending_frequency=="mensuel"){
                        listMen.push(l)
                    }
                }
                
                // console.log({listJour,listHeb,listMen})
                listJour.length==0?null:listFre.push("journalier")
                listHeb.length==0?null:listFre.push("hebdomadaire")
                listMen.length==0?null:listFre.push("mensuel")
               setOptions(listFre)
            //    console.log({listFre})
               setFrequence(listFre[0])
        }).catch(err=>{
            setUrls(["pas d'url dispo"])
            alert("La liste des Urls ne peut pas être récupéré")
        })

    }

    generate(token,data,setResult,setChagement,setInfoRapports){

        axios.post(`api-v0/generatePushmail`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
                 const result = res.data
                //  console.log({result})
                 setResult(result)
                 setInfoRapports(result)
                setChagement(false)
                if (result[0].bool=="False"){
                    alert("Impossible de générer le rapport. Fréquence non configurée")
                }
              
        }).catch(err=>{
            alert("Le rapport ne peut pas étre généré")
        })
    
    }

    envoyer(token,data,setChagement,i,result){
        // console.log("pos",{data})
        axios.post(`api-v0/sendMail`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
                //  const result = res.data
                // console.log("2",{data})
                 setChagement(false)
                 if (i==result.length){
                    alert("Rapport envoyé")
                }
              
        }).catch(err=>{
            alert("Le rapport ne peut pas étre envoyé")
        })
    
    }
    

    getAllRapport(token,id_client,id_site,setListRapprt,frequence,setAffiche,setVide,listRapprt){
        const data={id_client,id_site,frequence}
        // console.log({data})
        axios.post("api-v0/generatAllRapport",data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
                if(res.data=="error"){
                    alert("Le dossier n'existe pas")
                }else{
                    // console.log({res:res.data})
                    setListRapprt(res.data)
                    setAffiche(true)
                   res.data.length==0?setVide(true):null
                }
             
        }).catch(err=>{
            
        })
    
    }

}





export const postier = new Postier()
