import React,{useContext, useState} from 'react'
import './emails.scss'
import iconeModif from '/assets/iconeModif.png'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'




export function Emails() {

    const {site,clientt,token,update,emails,setEmails,setsousPages,role} = useContext(AppContext)
   
    
   
    React.useEffect(() => {
      if(site ){
          const id_client=clientt.id_client
          const id_site=site.id_site
          postier.getEmails(token,id_client,id_site,setEmails)
      }
      
  }, [site,token,update])


    function updateEmails(newEmail){
        // console.log("emails",emails)
        let listEmail=emails 
        listEmail.forEach(email => {
         email.oldEmail=email.email
          if (email.oldEmail==newEmail.oldEmail) {
            
            email=newEmail;
          }
        });
       
        setEmails(listEmail)
        // console.log({listEmail})
    }


    return (
        <div>
           {role=="Admin"?<button className="boutonAjoutEmail" onClick={()=>{setsousPages("ajoutEmail")}}>Ajouter un email</button>:null}
           <br/>

        <div className="bodyEmail">
       
            {emails[0]?emails.map(e=><div key={Math.random()}>
                <Email e={e} updateEmails={updateEmails} />
                </div>
            ):<div className="msg">La liste de diffusion est vide.</div>}
        </div>
        
        
        </div>
    )}


    function Email({e,updateEmails}){
      const {site,clientt,token} = useContext(AppContext)
      const [email,setEmaill]=React.useState(e.email)
      const [oldEmail,setOldEmail]=React.useState(e.email)
      const [is_active,setActive]=React.useState(e.is_active)
      
      const [update,setUpdate]=React.useState(false)
  
      const enregistrer = ()=>{
          setOldEmail(email)
          const emailInfo = {
              email,
              id_site:site.id_site,         
              id_client:clientt.id_client,           
              is_active ,
              oldEmail
          }
          
          
          postier.updateEmails(token,emailInfo,setUpdate)
          updateEmails(emailInfo)
          
      }
        
        return <div >
          {update?<ChangeEmail 
          e={e} 
          setEmaill={setEmaill} 
          setActive={setActive}
          enregistrer={enregistrer} 
          email={email}
          is_active={is_active} 
          />
          :<ShowEmail e={e} setIsModified={setUpdate} email={email} is_active={is_active}/>}
        </div> 
    }
  
    function ChangeEmail({e,setEmaill,setActive,enregistrer,email,is_active}){
      return<div className="email">
      <input onChange={e=>{setEmaill(e.target.value)}} className="inputMail"   defaultValue={email}/>
      <button onClick={enregistrer} className='boutonEnregistrer'>Enregistrer</button> 
      <button onClick={()=>{is_active==0?setActive(1):setActive(0)}} className='boutonActivation'>
          {is_active==0?"Activer":"Désactiver"}
      </button>
  
    
   
  </div> 
    }
  
  
    function ShowEmail({e,setIsModified,email,is_active}){
        
    const {role} = useContext(AppContext)
      return<div>
          
          <div className="email">
          
          <label>{email}</label>
          {role=="Admin"? <button className='boutonModif' onClick={()=>setIsModified(true)}>
              <img src={iconeModif} alt='iconeModif' className='iconeModif'/>
              </button>:null}
         
          <label className ="activation">{is_active==0?"Désactivé":"Activé"}</label>
          
          </div> 
          
      </div> 
  }
  