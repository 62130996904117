import axios from 'axios'
import 'regenerator-runtime/runtime'

const messages = {
    erreur: "connexion impossible"
}

class Postier{

    async getUrls(token,id_client,id_site,setUrls){
        // console.log({token})
        // console.log(`/api-v0/urls/${id_client}/${id_site}`)
        axios.get(`/api-v0/urls/${id_client}/${id_site}`,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
                const urls=res.data
                // console.log('type urles postier',typeof urls,{urls})
              
                setUrls(urls)
        }).catch(err=>{
            setUrls(["pas d'url dispo"])
            alert("La liste des Urls ne peut pas être récupéré")
        })

    }

    updateUrls(token,data,setIsModified){


    //    console.log("pos",{data})
        axios.post(`api-v0/url/update`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
                // console.log({data,result})
    
                if(result.id_client==data.id_client && result.id_site==data.id_site){
                    alert("Modification réussie!!")
                   
                    setIsModified(false)
                    // console.log("postier",res)
                }else{
                    console.log({err: res.data,data})
                    alert("Une erreur est survrvenue lors de la transaction")
                }
        }).catch(err=>{
            alert("L'Url ne peut pas être modifié")
        })
    
    }
    
    // ajoutUrl(token,data,setIsModified){

    //     //console.log("pos",{data})
    //     axios.post(`api-v0/url/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
    //         .then(res=>{
    
    //             const result = res.data
    //                 alert("Ajout réussi!!")
    //                 setIsModified(false)
    //                // console.log("postier",res)
              
    //     }).catch(err=>{
    //         alert("L'url ne peut pas étre ajouté")
    //     })
    
    // }

    // ajout(token,data,setIsModified,setsousPages,setAddJour,setAddHeb,setAddMen){

    //     //console.log("pos",{data})
    //     axios.post(`api-v0/url/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
    //         .then(res=>{
    
    //             const result = res.data
    //                 alert("Ajout réussi!!")
    //                 setIsModified(false)
    //                 setsousPages("urls")
    //                 setAddJour(false)
    //                 setAddHeb(false)
    //                 setAddMen(false)

    //                // console.log("postier",res)
              
    //     }).catch(err=>{
    //         console.log(err)
    //         alert("L'url ne peut pas étre ajouté")
    //     })
    
    // }


    
    ajoutUrls(token,data,setIsModified,setsousPages,setAddJour,setAddHeb,setAddMen){

        // console.log("pos",{data})
        axios.post(`api-v0/urls/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
                    alert("Ajout réussi!!")
                    setIsModified(false)
                    setsousPages("urls")
                    setAddJour(false)
                    setAddHeb(false)
                    setAddMen(false)

                   // console.log("postier",res)
              
        }).catch(err=>{
            console.log(err)
            alert("L'url ne peut pas étre ajouté")
        })
    
    }
    
}

export const postier = new Postier()