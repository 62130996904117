import axios from 'axios'
import 'regenerator-runtime/runtime'

const messages = {
    erreur: "connexion impossible"
}

class Postier{

    async getAllClients(token,setClients){
        // console.log({token})
        axios.get("/api-v0/clients/",{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
            //console.log("podtier",res.data)
            setClients(res.data)
        }).catch(err=>{
            alert("La liste des clients ne peut pas être récupérée")
        })

    }

       

    async getSites(token,client,setSites){
        //  console.log({id_client:client.id_client})
        //  console.log(`/api-v0/sites/${client.id_client}`)
        axios.get(`/api-v0/sites/${client.id_client}`,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
                // console.log('site postier',res.data)
                // console.log('type de site postier',typeof res.data)
                setSites(res.data)
                // console.log("site pos",res.data)
        }).catch(err=>{
            alert("La liste des sites ne peut pas être récupérée")
        })

    }

    updateClient(token,data,setIsModified){

        //console.log("pos",{data})
        axios.post(`api-v0/client/update`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
    
                if(result.id_client==data.id_client){
                    alert("Modification réussie!!")
                    setIsModified(false)
                   // console.log("postier",res)
                }else{
                    console.log({err: res.data,data})
                    alert("Une erreur est survrvenue lors de la transaction")
                }
        }).catch(err=>{
            alert("Le nom du client ne peut pas être modifié")
        })
    
    }
    

    updateSite(token,data,setIsModified){

        // console.log("pos",{data})
        axios.post(`api-v0/site/update`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
    
                if(result.id_site==data.id_site){
                    alert("Modification réussie!!")
                    setIsModified(false)
                    // console.log("postier",res)
                }else{
                    console.log({err: res.data,data})
                    alert("Une erreur est survrvenue lors de la transaction")
                }
        }).catch(err=>{
            alert("Le nom du site ne peut pas être modifié")
        })
    
    }
    
    


}

export const postier = new Postier()