import React,{useContext} from 'react'
import './ajoutClient.scss'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'



function NewClientSite(){
  const {setClientName,setIdClient,setIdSite,setSiteName}=useContext(AppContext)
    return  <table>
      <tr>
        <td> <label className="id">Id_client</label></td>
        <td><input className='inputAdd' onChange={e=>{setIdClient(e.target.value)}}/></td>
        <td><label className="nom">Nom Client</label></td>
        <td> <input  className='inputAdd'onChange={e=>{setClientName(e.target.value)}}/></td>
      </tr>
      <tr>
        <td><label className="id">Id_site</label></td>
        <td> <input className='inputAdd' onChange={e=>{setIdSite(e.target.value)}}/></td>
        <td> <label className="nom">Nom site</label></td>
        <td> <input className='inputAdd'  onChange={e=>{setSiteName(e.target.value)}}/></td>
      </tr>
             </table>
     }




export function AjoutClient() {
  const {token,setUpdate,client,id_client,site_name,id_site,addClientSite,setsousPages} = useContext(AppContext)
  

    const enregistrer = ()=>{

      const info = {
        site_name,
        id_site,
        id_client,
        client
    }
      
      postier.ajoutClientSite(token,info,setUpdate)
     // postier.getAllClients(token,setClients)
    //  console.log("cl",{clients})
      addClientSite(info)
      setsousPages("urls")
   
  }

    return <div className='ajoutPushMail'>
       
        <NewClientSite/> 
        <button className="boutonEnregistrer" onClick={enregistrer}>Enregistrer</button>
        <button className="boutonEnregistrer" onClick={()=>setsousPages("urls")}>Annuler</button>
        <br/><br/>
        </div> 


    }

 