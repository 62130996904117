import React,{useContext, useState} from 'react'
import './emails.scss'
import iconeModif from '/assets/iconeModif.png'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'




export function AjoutEmail() {

   const {token,setUpdate,site,clientt,setsousPages,emails,setEmails,addEmails}= useContext(AppContext)
   const [email,setEmail]=React.useState("")
   const [is_active,setIsActive]=React.useState(1)



    
   const enregistrer = ()=>{
    const emailInfo = {
        email,
        is_active,
        id_client:clientt.id_client,
        id_site:site.id_site
    }
    // console.log({emailInfo})
    postier.ajoutEmail(token,emailInfo,setUpdate)
    addEmails(emailInfo)
    setsousPages("emails")
    
}

    return (
        <div className="bodyEmail">
       
       <label className="nom">Email</label>
       <input  className='inputAddEmail'onChange={e=>setEmail(e.target.value)}/>
       
       <label className="nom">Is_active</label>
       <input type="checkbox"  defaultChecked={is_active} onChange={()=>{{
           setIsActive(!is_active)}
           is_active==false?setIsActive(1):setIsActive(0)}}/>
       <br/>
       <button className="boutonEnregistrer" onClick={enregistrer}>Enregistrer</button>
        <button className="boutonEnregistrer" onClick={()=>setsousPages("emails")}>Annuler</button>
        <br/> <br/>
        </div>
    )}



     
       

  