import React,{useContext} from 'react'
import pdf from '/assets/PDF_file_icon.svg'
import loading from '/assets/loading.gif'
import test from '/assets/test.pdf'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'
import './generatePushMail.scss'




export function AfficherPushMails() {
   const {token,clientt,site,setListRapprt,listRapprt,setsousPages,manque,setUrls,urls,setOptions,options}= useContext(AppContext)
   const [frequence,setFrequence]=React.useState("")
   const [affiche,setAffiche]=React.useState(false)
   const [id_client,setIdClient]=React.useState(clientt.id_client)
   const [id_site,setIdSite]=React.useState(site.id_site)
    const [update,setUpdate]=React.useState(false)
    const [vide,setVide]=React.useState(false)
    const [listUrls,setListUrls]=React.useState(urls)
    const [listFre,setListFre]=React.useState([])


   

   const afficher = ()=>{
       postier.getAllRapport(token,clientt.id_client,site.id_site,setListRapprt,frequence,setAffiche,setVide,listRapprt)
       
}

    const annuler = ()=>{
        setAffiche(false)
        setsousPages("urls")
    }

    React.useEffect(() => {
        setAffiche(false);
        setListFre([])
        
    },[clientt,site])

   React.useEffect(() => {
        postier.getUrls(token,clientt.id_client,site.id_site,setListUrls,listFre,setOptions,setUrls,setFrequence)
        // console.log({listUrls})
   },[clientt,site])



    return <div className='ajoutPushMail'>
          <button className="boutonEnregistrer"  onClick={afficher}>Afficher les rapports</button>
        <button className="boutonEnregistrer"  onClick={annuler}>Quitter</button>
        <table>
          <tr>
              <td><label className='frequence'>Fréquence d'envoi</label></td>
              <td>
                  <select id="frequence" className="selectUpdate" defaultValue={options[0]} onChange={e=>{setFrequence(e.target.value)
                setVide(false)
               }} >
                    {options.map(opt=>
            <option value={opt} >{opt}</option>
            )}
            </select></td>
        </tr>

          <tr>
              <td><label className="id">Id_client</label></td>
              <td><label >{clientt.id_client}</label></td>
              <td><label className="nom">Client</label></td>
              <td><label >{clientt.client}</label></td>
          </tr>
          <tr>
              <td><label className="id">Id_site</label></td>
              <td><label>{site.id_site}</label></td>
              <td><label className="nom">Site</label></td>
              <td><label >{site.site_name}</label></td>
              
          </tr>
          </table>
                   {affiche==true?<label className="nbRapport">{listRapprt.length} rapport(s) trouvé(s)</label>:null}
                   <br/><br/>
        { affiche==true?listRapprt.map(e=><div key={e}>
            
        <a className='link' href={"/PDF/pushmail/pushmail_"+clientt.id_client+"/"+site.id_site+"/"+frequence+"/archives/"+e} target="_blank"><img src={pdf} alt='pdf' className='iconeModif'/><label className='fileName'>{e}</label></a>
       
            </div>):null} 
            {/* {vide==true?<label >Aucun rapport n'as été trouvé</label>:null} */}
      
        <br/><br/>
    </div>


}


