import React,{useContext} from 'react'
import "./menu.scss"
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'
import iconeModif from '/assets/iconeModif.png'


export function ClientInfo() {
    const {clients,setClients,clientt} = useContext(AppContext)
    const [client,setClient]=React.useState(clientt.client)

    function updateClients(newClient){
      let listeClient=clients 
      listeClient.forEach(cl => {
        if (cl.id_client==newClient.id_client) {
          cl.client=newClient.client;
          
        }
      });
      //console.log({listSite})
      setClients(listeClient)
    }

  return (
    <div>
    <div className="body_update_Client">
        {
            <Client c={clientt} updateClients={updateClients} client={client} setClient={setClient}/>
           
        }
    </div>
    
    
    </div>

)
}

function Client({c,updateClients,client,setClient}){
  const {clientt,token,setClientt,update,setUpdate} = useContext(AppContext)
  
  
  // const [update,setUpdate]=React.useState(false)

    const enregistrer = ()=>{
        const clientInfo = {
            client,
            id_client:clientt.id_client
        }
        
        postier.updateClient(token,clientInfo,setUpdate)
        setClientt(clientInfo)
        updateClients(clientInfo)
    }
      return <div >
        {update?<ChangeClient 
        c={c} 
        setClient={setClient} 
        enregistrer={enregistrer} 
         />
        :<ShowClient c={c} setIsModified={setUpdate} />}
      </div> 
  }

  function ChangeClient({c,setClient,enregistrer}){
    // setSiteName(site.site_name)
    return <div className='bodyUpdate'>
    <label className="id">Id_client</label>
    <label>{c.id_client}</label>
    <label className="name">Client</label>
    <input Value={c.client} className='inputUpdate'  onChange={e=>{setClient(e.target.value)}}/>
  <button onClick={enregistrer} className="boutonEnregistrer">Enregistrer</button>

</div>
  }


  function ShowClient({c,setIsModified}){
    const{setUpdate,role}= useContext(AppContext)
    return <div className='bodyUpdate'>
            <label className="id">Id_client</label>
            <label>{c.id_client}</label>

            <label className="name">Client</label>
            <label >{c.client}</label>
            {role=="Admin"?<button className='boutonModif' onClick={()=>setUpdate(true)}>
                    <img src={iconeModif} alt='iconeModif' className='iconeModif'/>
                </button>:null}
            
    
    </div> 
}


 




