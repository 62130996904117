import './connexion.scss'
import React,{useContext, useEffect, useState} from 'react'
import {postier} from './Postier'
import {} from '../Header/index'
import {AppContext} from '../../context/Appcontext'
import logo from '/assets/logo.png'

export function Connexion() {

    const {setPage,setToken,setRole} = useContext(AppContext)


    const [username,setUsername]=useState('')
    const [password,setPassword]=useState('')

    useEffect(()=>{
        // setToken(localStorage.getItem("token"))

        const token = localStorage.getItem("token")

        // postier.checkToken(token,setPage)

    },[])

    const login= ()=>{postier.login(username,password,setPage,setToken,setRole)}
    

    return (
        
        <div>
           
           <div className='body'>
                <img src={logo} alt='cliris-group' className='logoConnexion' /><br/>
                <h4 className='title'>Push Mail AdminTool</h4>
                <input type='text' placeholder="Nom d'utilisateur"className='email' class='input' value={username} onChange={e=>setUsername(e.target.value) }/>
                <br/>
                <input type='password' placeholder='Mot de passe'  className='pwd' class='input'value={password} onChange={e=>setPassword(e.target.value) }/>
                <br/>
                <button type='submit'  className='seConnecter' onClick={login} >SE CONNECTER</button>
            </div>
            
                
            </div>

    )}

  