import axios from 'axios'
import 'regenerator-runtime/runtime'

const messages = {
    erreur: "connexion impossible"
}


class Postier{

    async login(username,password,setPage,setToken,setRole){
        axios.post("/auth/login",{username,password})
            .then(res=>{
                // console.log("connexion",res.data)

                if(res.data.connexion){
                    setPage("main")
                    setToken(res.data.token)
                    localStorage.setItem("token",res.data.token)
                    localStorage.setItem("role",res.data.role)
                    setRole(res.data.role)
                    
                }else{
                    alert("Mot de passe ou identifiant incorrect")
                }
            
        }).catch(err=>{

            console.log(err)
            alert("Connexion au serveur impossible")
        })

    }

       
    // checkToken(token){
    //     // console.log({token})
    //     axios.post("/api-v0/checktoken",{token},{headers:{ Authorization: `Bearer ${token}` }}).then(res=>{
    //         // console.log(res.data)
    //         // setPage("main")
    //     }).catch(err=>{
    //         // alert("errur")
    //         console.log({err})
    //     })
            
    // }
   

   


}

export const postier = new Postier()