import React,{useContext} from 'react'
import logo from '/assets/logo.png'
import "./header.scss"

import {AppContext} from '../../context/Appcontext'



export function Header() {
    const {setPage,pages} = React.useContext(AppContext)

    function deconnexion(){
        setPage("login")
        localStorage.clear()
        // setToken(undefined)
    }

    return (
           <div className="header">
               
                
                <img src={logo} alt='cliris-group' className='logo' />
                <label className='titre' >Push Mail AdminTool</label>
                <button type='submit' className='seDeconnecter' onClick={deconnexion}>Déconnexion</button>
                
            </div>

    )}

  