import React,{useContext} from 'react'
import pdf from '/assets/PDF_file_icon.svg'
import loading from '/assets/loading.gif'
import test from '/assets/test.pdf'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'
import './generatePushMail.scss'

export function GeneratePushMail() {
    const {clientt,site,token,setsousPages,result,setResult,urls,setUrls,setInfoRapports,quitter,setQuitter}= useContext(AppContext)
    const [date_1,setDate_1]=React.useState("")
    const [date_2,setDate_2]=React.useState(date_1)
    const [chargement,setChagement]=React.useState(false)
    const [listUrls,setListUrls]=React.useState(urls)
    const [listFre,setListFre]=React.useState([])
    const [frequence,setFrequence]=React.useState("journalier")
    const [dateDuJour,setDateDuJour]=React.useState("")
    const options = ["journalier","hebdomadaire","mensuel"]
   
    React.useEffect(() => {
        var date=new Date()
        let jour=date.getDate()
        let mois=date.getMonth()+1
        let annee=date.getFullYear()
        mois<10?mois="0"+mois:null
        let d=annee+"-"+mois+"-"+jour
        setDateDuJour(d)
        setDate_1(d)
        setDate_2(d)
    },[clientt,site])
   
    const valider = ()=>{
        const info = {
            id_client:clientt.id_client,
            id_site:site.id_site,
            frequence,
            date_1,
            date_2
        }
        setChagement(true)
         console.log({info})
        postier.generate(token,info,setResult,setChagement,setInfoRapports)
       
        
    }


    const envoyer = ()=>{
        let i=0
        result.forEach(res => {
            // setMsg(res.msg)
            const info = {
                listMail:res.listMail,
                filename:res.filename,
                subject:res.subject,
                msg:res.msg,
                fileToAttach:res.fileToAttach
            }
            setChagement(true)
            i++
            postier.envoyer(token,info,setChagement,i,result)
            
        });
      
    }

    const annuler = ()=>{
        setResult([])
        setsousPages("urls")
      
    }

//     React.useEffect(() => {
//         postier.getUrls(token,clientt.id_client,site.id_site,setListUrls,listFre,setOptions,setUrls,setFrequence)
//         // console.log({listUrls})
//    },[clientt,site,quitter])

   React.useEffect(() => {
       console.log({site})
       if(quitter==false){
        setListFre([])
        setResult([])
       }
       setQuitter(false)
       console.log({result})
},[site])



  return  <div className='ajoutPushMail'>
      <table>
          <tr>
              <td><label className='frequence'>Fréquence d'envoi</label></td>
              <select id="frequence" className="selectUpdate" defaultValue={options[0]} onChange={e=>{setFrequence(e.target.value)
                setResult([])}} >
                    {options.map(opt=>
            <option value={opt} >{opt}</option>
            )}
            </select>
        </tr>

          <tr>
              <td><label className="id">Id_client</label></td>
              <td><label >{clientt.id_client}</label></td>
              <td><label className="nom">Client</label></td>
              <td><label >{clientt.client}</label></td>
          </tr>
          <tr>
              <td><label className="id">Id_site</label></td>
              <td><label>{site.id_site}</label></td>
              <td><label className="nom">Site</label></td>
              <td><label >{site.site_name}</label></td>
              
          </tr>
          
          <tr>
              <td><label className="id">De : </label></td>
              <td><input type="date" Value={date_1} onChange={e=>{setDate_1(e.target.value)
                setDate_2(e.target.value)}}/></td>
              <td><label className="nom">A : </label></td>
              <td><input type="date" Value={date_1} onChange={e=>setDate_2(e.target.value)}/></td>
          </tr>
          <br/>
          <tr>
              <td>
                {result.length>0 && result[0].bool!="False"?<label className="dateRapport">Date du rapport</label>:null}
              </td>
              <td>
                  {result.length>0 && result[0].bool!="False"?<label className="pdfnom">PDF</label>:null}
              </td>
          </tr>
          <tr>
              <td>{result.length>0 && result[0].bool!="False"?<div className="separ"></div>:null}</td>
              <td>{result.length>0 && result[0].bool!="False"?<div className="separ2"></div>:null}</td>
        </tr>
          <tr>
          <td> 
          {result[0] ?result.map(e=><div className="date" key={Math.random()}>
               {e.bool!="False"?((e.fileToAttach.split('/archives/'))[1]).split("_")[2].split(".pdf")[0]:null}
                </div>):null}
                {/* {result[0] &&( urls[0].sending_frequency=="journalier" || urls[0].sending_frequency=="hebdomadaire")?result.map(e=><div className="date" key={Math.random()}>
               {e.bool!="False"?(e.fileToAttach.slice(e.fileToAttach.length-14,e.fileToAttach.length-4)):null}
                </div>):null} */}
                <label ></label>
                
            </td> 
           <td> 
               {result.length>0 && result[0].bool!="False" ?result.map(e=><div key={Math.random()}>
                <a className='link' href={"/PDF/"+e.fileToAttach} target="_blank"><img src={pdf} alt='pdf' className='iconeModif'/><label className='fileName'>{(e.fileToAttach.split('/archives/'))[1]}</label></a>
              
                </div>):null}
              
                
            </td>

          </tr>
         
            {chargement==true?<img src={loading} alt='loading' className='iconeModif'/>:null}
      </table>
      
      
      {result.length>0 && result[0].bool!="False"? <button className="boutonEnregistrer" onClick={envoyer}>Envoyer avec les paramètres par défault</button>:null}
      {result.length>0 && result[0].bool!="False"?<button  className="boutonEnregistrer" onClick={()=>setsousPages("envoyerPushMail")}>Modifier les paramètres d'envoi</button>:null}
        <button className="boutonEnregistrer"  onClick={valider}>Générer les rapports</button>
         <button className="boutonEnregistrer" onClick={annuler}>Annuler</button>
         <br/><br/>
        </div>
}


