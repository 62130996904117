import React,{useContext} from 'react'
import "./menu.scss"
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'
import iconeModif from '/assets/iconeModif.png'


export function SiteInfo() {
    const {sites,setSites,site} = useContext(AppContext)
    const [site_name,setSiteName]=React.useState(site.site_name)

    function updateSites(newSite){
      let listSite=sites 
      listSite.forEach(site => {
        if (site.id_site==newSite.id_site) {
          site.site_name=newSite.site_name;
          
        }
      });
      //console.log({listSite})
      setSites(listSite)
    }

  return (
    <div>
    <div className="body_update_Client">
        {
            <Site s={site} updateSites={updateSites} site_name={site_name} setSiteName={setSiteName}/>
           
        }
    </div>
    
    
    </div>

)
}

function Site({s,updateSites,site_name,setSiteName}){
  const {site,token,setSite} = useContext(AppContext)
  
  
  const [update,setUpdate]=React.useState(false)

    const enregistrer = ()=>{
        const siteInfo = {
            site_name,
            id_site:site.id_site
        }
        
        postier.updateSite(token,siteInfo,setUpdate)
        setSite(siteInfo)
        updateSites(siteInfo)
    }
      return <div >
        {update?<ChangeSite 
        s={s} 
        setSiteName={setSiteName} 
        enregistrer={enregistrer} 
         />
        :<ShowSite s={s} setIsModified={setUpdate} />}
      </div> 
  }

  function ChangeSite({s,setSiteName,enregistrer}){
    // setSiteName(site.site_name)
    return <div className='bodyUpdate'>
    <label className="id">Id_site</label>
    <label>{s.id_site}</label>
    <label className="name">site</label>
    <input Value={s.site_name} className='inputUpdate' onChange={e=>{setSiteName(e.target.value)}}/>
  <button onClick={enregistrer} className="boutonEnregistrer">Enregistrer</button>

</div>
  }


  function ShowSite({s,setIsModified}){
    const{role}= useContext(AppContext)
    return <div className='bodyUpdate'>
            <label className="id">Id_site</label>
            <label>{s.id_site}</label>

            <label className="name">Site</label>
            <label >{s.site_name}</label>
            {role=="Admin"?<button className='boutonModif' onClick={()=>setIsModified(true)}>
                    <img src={iconeModif} alt='iconeModif' className='iconeModif'/>
                </button>
    :null}
    </div> 
}


 




