import axios from 'axios'
import 'regenerator-runtime/runtime'

const messages = {
    erreur: "connexion impossible"
}

class Postier{

    async getEmails(token,id_client,id_site,setUrls){
        // console.log({token})
        // console.log(`/api-v0/emails/${id_client}/${id_site}`)
       axios.get(`/api-v0/emails/${id_client}/${id_site}`,{headers:{ Authorization: `Bearer ${token}` }})
           .then(res=>{
               const emails=res.data
               setUrls(emails)
       }).catch(err=>{
           alert("La liste des emails ne peut pas être récupérée")
       })

   }

   updateEmails(token,data,setIsModified){

    //console.log("pos",{data})
    axios.post(`api-v0/email/update`,data,{headers:{ Authorization: `Bearer ${token}` }})
        .then(res=>{

            const result = res.data

            if(result.oldEmail==data.oldEmail ){
                alert("Modification réussie!!")
                setIsModified(false)
                // console.log("postier",res)
            }else{
                console.log({err: res.data,data})
                alert("Une erreur est survrvenue lors de la transaction")
            }
    }).catch(err=>{
        alert("L'email ne peut pas être modifié")
    })

}


ajoutEmail(token,data,setIsModified){

    //console.log("pos",{data})
    axios.post(`api-v0/email/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
        .then(res=>{

            const result = res.data
                alert("Ajout réussi!!")
                setIsModified(false)
               // console.log("postier",res)
          
    }).catch(err=>{
        alert("L'email ne peut pas étre ajouté")
    })

}

}



export const postier = new Postier()