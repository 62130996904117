import React, { useContext } from 'react'
import {render} from 'react-dom'
import {AppProvider,AppContext} from '../context/Appcontext'
import {Connexion} from './Connexion/index'
import {Menu} from './Menu/index'
import {Header} from './Header/index'
import {Urls} from './Urls/index'
import {Emails} from './emails/index'
import { ClientInfo } from './Menu/clientInfo'
import { SiteInfo } from './Menu/siteInfo'
import {AjoutClient} from './AjoutClient/ajoutClient'
import {AjoutSite} from './AjoutSite/index'
import {AjoutEmail} from './emails/ajoutEmail'
import {AjoutUrl} from './Urls/ajoutUrl'
import {GeneratePushMail} from './GeneratePushMail/index'
import {AfficherPushMails} from './GeneratePushMail/afficherPushMails'
import {EnvoyerPushMail} from './GeneratePushMail/envoyerPushMail'

function App() {
    return (
      <AppProvider>
        <Display/>
        </AppProvider>
    )
       
}


function Display(){
  const {page,token,pages} = useContext(AppContext)
  return(
    <div>
      {(page=="login" )?<Connexion/>:<Content/>}
    
    </div>
   
  )
}


function Content() {

  const {pages} = useContext(AppContext)
  return (
  <div>
    <Header/>
    <Menu />
    {(pages=="siteInfo" )?<SiteInfo/>:<null/>}
    {(pages=="client" )?<ClientInfo/>:<null/>}
    {pages=="urls"?<Urls/>:null}
    {pages=="emails"?<Emails/>:null}
    {pages=="ajoutClient"?<AjoutClient/>:null}
    {pages=="ajoutSite"?<AjoutSite/>:null}
    {pages=="ajoutEmail"?<AjoutEmail/>:null}
    {pages=="ajoutUrl"?<AjoutUrl/>:null}
    {pages=="genererPushMail"?<GeneratePushMail/>:null}
    {pages=="AfficherPushMails"?<AfficherPushMails/>:null}
    {pages=="envoyerPushMail"?<EnvoyerPushMail/>:null}
  </div>
  
  )
  
}




render(<App/>,document.querySelector("#app"))