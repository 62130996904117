import React,{useContext} from 'react'
import "./menu.scss"
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'


export function Menu() {
    const {clientt,setClientt,site,setSite,token,page,
        setsousPages,pages,sites,setSites,update,listVideJour,listVideHeb,listVideMen,urls,role} = useContext(AppContext)
    const [clients, setClients] = React.useState([])


        
    React.useEffect(() => {
        if(token!=""){
       postier.getAllClients(token,setClients)}
    },[token,page,update])

    React.useEffect(() => {
        const cli=JSON.parse( localStorage.getItem('client'))
        // console.log({cli})
        clients[0]?setClientt(cli||clients[0]):null;
        
    }, [clients])
   

    React.useEffect(() => {
        if(clientt && token) {
        //    console.log({clientt})
            postier.getSites(token,clientt,setSites)
            localStorage.setItem('client',JSON.stringify(clientt))

        }
      
    }, [clientt,token,update])


    React.useEffect(() => {
       // console.log("indexxx",{sites})
        sites[0]?setSite(sites[0]):null;

    }, [sites])


 


  return  <div className="menu">
                    
                    <label className='label'>Client</label><br/>
                    <select className='select' value={JSON.stringify(clientt)} onChange={e=>{setClientt(
                        JSON.parse(e.target.value))}}>
                        
                      
                        {
                           clients.map(cl=>{
                               return<option value={JSON.stringify(cl)} key={cl.id_client} >
                                   {cl.id_client}
                               </option>
                           })

                        }
                    </select> 

                {/* <button onClick={()=>{setsousPages("updateClient")}}>modifier</button> */}
                    <br/>

                    <label className='label'>Site</label><br/>
                  {/* <button onClick={()=>console.log("bouton site",sites)}>show sites</button> */}
                    <select className='select' value={JSON.stringify(site)} onChange={e=>{setSite(
                       JSON.parse(e.target.value))}}>
                        {
                           sites.map(s=>{
                               return<option value={JSON.stringify(s)} key={s.id_site} >
                                   {s.site_name}
                               </option>
                           })

                        }
                    </select> 
                    {/* <button onClick={()=>setsousPages("updateSite")}>modifier</button> */}
                    <button onClick={()=>{setsousPages("client")}}className={pages=="client"?"selected":"bouton"}>Informations du client</button>
                    <button onClick={()=>{setsousPages("siteInfo")}}className={pages=="siteInfo"?"selected":"bouton"}>Informations du site</button>
                    <div className="sousMenu">
                    <button className={pages=="urls"?"selected":"bouton"}  onClick={()=>{setsousPages("urls")}}>Urls</button>
                    <button className={pages=="emails"?"selected":"bouton"} onClick={()=>{setsousPages("emails")}}>Liste de diffusion</button>
                    </div>
                    <div className="sousMenu">
                    
                    {role=="Admin"?<button className={pages=="ajoutClient"?"selected":"bouton"} onClick={()=>{setsousPages("ajoutClient")}}>Ajouter un client</button>:null}
                    {role=="Admin"?<button className={pages=="ajoutSite"?"selected":"bouton"} onClick={()=>{setsousPages("ajoutSite")}}>Ajouter un site</button>:null}
                    {role=="Admin"? <button className={pages=="genererPushMail"?"selected":"bouton"} onClick={()=>setsousPages("genererPushMail")}>Générer les rapports</button>:null} 
                    <button className={pages=="AfficherPushMails"?"selected":"bouton"} onClick={()=>{setsousPages("AfficherPushMails")}}>Tout les rapports</button>
                   
                    </div>
                    
                    </div>
}

 