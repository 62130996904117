import axios from 'axios'
import 'regenerator-runtime/runtime'



class Postier{

    async getAllClients(token,setClients){
        // console.log({token})
        axios.get("/api-v0/clients/",{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
            //console.log("podtier",res.data)
            setClients(res.data)
        }).catch(err=>{
            alert("La liste des clients ne peut pas être récupérée")
        })

    }


    ajoutClientSite(token,data,setIsModified){

        //console.log("pos",{data})
        axios.post(`api-v0/clientSite/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
                    alert("Ajout réussi!!")
                    setIsModified(false)
                   // console.log("postier",res)
              
        }).catch(err=>{
            alert("Le client ne peut pas étre ajouté")
        })
    
    }

    ajoutSite(token,data,setIsModified){

        //console.log("pos",{data})
        axios.post(`api-v0/site/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
                    alert("Ajout réussi!!")
                    setIsModified(false)
                   // console.log("postier",res)
              
        }).catch(err=>{
            alert("Le site ne peut pas étre ajouté")
        })
    
    }

    ajoutUrl(token,data,setIsModified){

        //console.log("pos",{data})
        axios.post(`api-v0/url/add`,data,{headers:{ Authorization: `Bearer ${token}` }})
            .then(res=>{
    
                const result = res.data
                    alert("Ajout réussi!!")
                    setIsModified(false)
                   // console.log("postier",res)
              
        }).catch(err=>{
            alert("L'url ne peut pas étre ajouté")
        })
    
    }



}

export const postier = new Postier()