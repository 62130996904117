import React,{useContext,useState,useEffect} from 'react'
import './urls.scss'
import iconeModif from '/assets/iconeModif.png'
import plus from '/assets/plus.jpg'
import moins from '/assets/moins.png'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'



const options = ["journalier","hebdomadaire","mensuel"]
const optionsPage=["a3","a4"]
const optionsMode=["Automatique","Manuel"]



export function Urls() {

    const {addUrls,setManque,site,clientt,token,update,setUrls,urls,setsousPages,role
        ,setAddJour,setAddHeb,setAddMen,listVideJour,setListVideJour,listVideHeb,setListVideHeb,
        listVideMen,setListVideMen,setFrequence,setListFre} = useContext(AppContext)
    
    
    const [sending_frequency,setSendingFrequency]=React.useState("")
    const [listUrls,setListUrls]=React.useState([])
   const [updateList,setUpdateList]=React.useState(false)

   const [listUrlsHeb,setListUrlsHeb]=React.useState([])
   const [updateListHeb,setUpdateListHeb]=React.useState(false)

   const [listUrlsMen,setListUrlsMen]=React.useState([])
   const [updateListMen,setUpdateListMen]=React.useState(false)

   

    React.useEffect(() => {
        if(listUrls[0]){
            console.log(listUrls)
        }
    },[listUrls])

    React.useEffect(() => {
        if(listUrlsHeb[0]){
            console.log(listUrlsHeb)
        }
    },[listUrlsHeb])

    React.useEffect(() => {
        if(listUrlsMen[0]){
            console.log(listUrlsMen)
        }
    },[listUrlsMen])
 

    const addNewUrl=()=>{
       const urlTemp= {url:"",
       id_site:"",
       id_client:"",
       sending_frequency:"",
       page_format:"",
       is_active:1,
       auto_mode:1,
       order_:1}
      
       let urlsTemp=listUrls
       urlsTemp.push(urlTemp)
       setListUrls(urlsTemp)
        setUpdateList(!updateList)
    }

    const addNewUrlHeb=()=>{
        const urlTemp= {url:"",
        id_site:"",
        id_client:"",
        sending_frequency:"",
        page_format:"",
        is_active:1,
        auto_mode:1,
        order_:1}
       
        let urlsTemp=listUrlsHeb
        urlsTemp.push(urlTemp)
        setListUrlsHeb(urlsTemp)
         setUpdateListHeb(!updateListHeb)
     }

     const addNewUrlMen=()=>{
        const urlTemp= {url:"",
        id_site:"",
        id_client:"",
        sending_frequency:"",
        page_format:"",
        is_active:1,
        auto_mode:1,
        order_:1}
       
        let urlsTemp=listUrlsMen
        urlsTemp.push(urlTemp)
        setListUrlsMen(urlsTemp)
         setUpdateListMen(!updateListMen)
     }
 

    const removeNewUrl=()=>{
       
        let list=listUrls
        setUpdateList(!updateList)
        list.pop()
        setListUrls(list)
        listUrls.length==0?setsousPages("urls"):null
    }

    const removeNewUrlHeb=()=>{
       
        let list=listUrlsHeb
        setUpdateListHeb(!updateListHeb)
        list.pop()
        setListUrlsHeb(list)
        listUrlsHeb.length==0?setsousPages("urls"):null
    }

    const removeNewUrlMen=()=>{
       
        let list=listUrlsMen
        setUpdateListMen(!updateListMen)
        list.pop()
        setListUrlsMen(list)
        listUrlsMen.length==0?setsousPages("urls"):null
    }

    const enregistrer = ()=>{
        // console.log({listUrls})
        postier.ajoutUrls(token,listUrls,setUpdateList,setsousPages,setAddJour,setAddHeb,setAddMen,setAddHeb,setAddMen)
        addUrls(urls)
        postier.getUrls(token,clientt.id_client,site.id_site,setUrls)
        setListUrls([])
        
     }

     const enregistrerHeb = ()=>{
        // console.log({listUrlsHeb})
        postier.ajoutUrls(token,listUrlsHeb,setUpdateListHeb,setsousPages,setAddJour,setAddHeb,setAddMen,setAddHeb,setAddMen)
        addUrls(urls)
        postier.getUrls(token,clientt.id_client,site.id_site,setUrls)
        setListUrlsHeb([])
        
     }

     const enregistrerMen = ()=>{
        // console.log({listUrlsMen})
        postier.ajoutUrls(token,listUrlsMen,setUpdateListMen,setsousPages,setAddJour,setAddHeb,setAddMen,setAddHeb,setAddMen)
        addUrls(urls)
        postier.getUrls(token,clientt.id_client,site.id_site,setUrls)
        setListUrlsMen([])
        
     }

   
    React.useEffect(() => {
        if(site ){
            const id_client=clientt.id_client
            const id_site=site.id_site
            postier.getUrls(token,id_client,id_site,setUrls)
          
        }
        
    },[site,token,update])

   
    React.useEffect(() => {
      const jour= document.getElementById("jour")
      const heb=document.getElementById("heb")
      const men=document.getElementById("men");
    //   console.log({jour,heb,men})
      let list=[]

        jour==null?list.push("journalier"):null
        heb==null?list.push("hebdomadaire"):null
        men==null?list.push("mensuel"):null
        setManque(list)
        setListFre(list)
    },[urls])


    function updateUrls(newUrl){
        let listUrl=urls 
        listUrl.forEach(url => {
          if (url.id_site==newUrl.id_site) {
            url=newUrl;
            
          }
        });
        // console.log({listUrl})
        setUrls(listUrl)
      }

    function UrlsJour() {
        let list = []
    
        for (const url of urls) {

            if(url.sending_frequency=="journalier"){
                list.push(url)
                list.sort(function (a, b) {
                    return a.order_ - b.order_;
                });
            }
        }
       
    
        const journalier  = list.map(u=><div key={Math.random()}>
            
            <Url u={u} updateUrls={updateUrls}/>  
    </div> )


        return <div className='divUrl'>
        {list.length>0?<div><br/><label className='frequence'>Fréquence d'envoi</label>
                        <label id="jour">{list[0].sending_frequency}</label>
        
                   {role=="Admin"? <button className='boutonModifUrl'  
          onClick={addNewUrl} ><img src={plus} alt='plus' className='iconeModif'/></button>:null}  

        {listUrls.length>0?<button  className='boutonModifUrl'
             onClick={removeNewUrl} ><img src={moins} alt='moins'className='iconeModif' /></button>:null} 

                        <br/><br/>
                        <div className='ligne'><label className='urlTitre'>URL</label>
                   
                    <label className="formatTitre" >Format </label>
                    <label className="modeTitre">Mode</label>
                    <label  className='pageTitre'>Page</label>
                       </div></div>:setListVideJour(true)}
                    
            {journalier}
            
          {list.length>0 ?listUrls.map((u,i)=><NewUrl u={u} setListUrls={setListUrls} index={i} setUpdateList={setUpdateList} sending_frequency={"journalier"} order_={list.length+i+1}/>):null}  
          {list.length>0?listUrls[0]? <button className="boutonEnregistrer" onClick={enregistrer}>Enregistrer</button>:null:null}
          {list.length>0?listUrls[0]?<button   className="boutonEnregistrer"  onClick={()=>setListUrls([])}>Annuler</button>:null:null}
            {list.length>0?<div><br/><br/></div>:null}
                </div>

               
    
    }
          

    
    function UrlsHeb() {
    let list = []

    for (const url of urls) {
        if(url.sending_frequency=="hebdomadaire"){
            list.push(url)
            list.sort(function (a, b) {
                return a.order_ - b.order_;
            });
        }
    }

    const hebdomadaire  = list.map(u=><div key={Math.random()}>
            
            <Url u={u} updateUrls={updateUrls}/>
             
</div> )

    return <div className='divUrl'>
    {list.length>0?<div><br/><label className='frequence'>Fréquence d'envoi</label>
                    <label id="heb" >{list[0].sending_frequency}</label>
                    
                    {role=="Admin"? <button className='boutonModifUrl'  
          onClick={addNewUrlHeb} ><img src={plus} alt='plus' className='iconeModif'/></button>:null}  

        {listUrlsHeb.length>0?<button className='boutonModifUrl'  
             onClick={removeNewUrlHeb} ><img src={moins} alt='moins' className='iconeModif'/></button>:null}
                        
  
    <br/><br/>         
                    <div className='ligne'><label className='urlTitre'>URL</label>
                    
                    <label className="formatTitre" >Format </label>
                    <label className="modeTitre">Mode</label>
                    <label  className='pageTitre'>Page</label>
                    
                     </div></div>:setListVideHeb(true)}
    
      {hebdomadaire}

      {list.length>0?listUrlsHeb.map((u,i)=><NewUrlHeb u={u} setListUrlsHeb={setListUrlsHeb} index={i} setUpdateListHeb={setUpdateListHeb} sending_frequency={"hebdomadaire"} order_={list.length+i+1}/>):null}
      {list.length>0?listUrlsHeb[0]?<button className="boutonEnregistrer"  onClick={enregistrerHeb}>Enregistrer</button>:null:null}
            {list.length>0?listUrlsHeb[0]?<button className="boutonEnregistrer" onClick={()=>setListUrlsHeb([])}>Annuler</button>:null:null}
            {list.length>0?<div><br/><br/></div>:null}
            </div>

}
      

      function UrlsMens() {
        let list = []

        for (const url of urls) {
            if(url.sending_frequency=="mensuel"){
                list.push(url)
                list.sort(function (a, b) {
                    return a.order_ - b.order_;
                });
            }
        }

        const mensuel  = list.map(u=><div key={Math.random()}>
                 
               <Url u={u} updateUrls={updateUrls}/>
               
                 
    </div> )

        return <div className='divUrl' >
        {list.length>0?<div >
            <br/>
                        <label className='frequence'>Fréquence d'envoi</label>
                      <label id="men"> {list[0].sending_frequency}</label>
                      
                      {role=="Admin"? <button className='boutonModifUrl'  
          onClick={addNewUrlMen} ><img src={plus} alt='plus' className='iconeModif'/></button>:null}  

       
    {listUrlsMen.length>0?<button className='boutonModifUrl'  
             onClick={removeNewUrlMen} ><img src={moins} alt='moins' className='iconeModif'/></button>:null}
   
                        <br/><br/>
                        <div className='ligne'><label className='urlTitre'>URL</label>
                    
                    <label className="formatTitre" >Format </label>
                    <label className="modeTitre">Mode</label>
                    <label  className='pageTitre'>Page</label>
                      </div></div>:setListVideMen(true)}

                     
                       
                        <div >  {mensuel}
            {list.length>0?listUrlsMen.map((u,i)=><NewUrlMen u={u} setListUrlsMen={setListUrlsMen} index={i} setUpdateListMen={setUpdateListMen} sending_frequency={"mensuel"} order_={list.length+i+1}/>):null}
            {list.length>0?listUrlsMen[0]?<button className="boutonEnregistrer"  onClick={enregistrerMen}>Enregistrer</button>:null:null}
            {list.length>0?listUrlsMen[0]?<button className="boutonEnregistrer"  onClick={()=>setListUrlsMen([])}>Annuler</button>:null:null}
            {list.length>0?<div><br/><br/></div>:null}
                      
                        </div>


      
                </div>
    
}

    return (<div>
     {(listVideJour==true || listVideHeb==true || listVideMen==true || urls==[]) && role=="Admin"?
         <div className="div"> <button className="boutonAjoutUrl" onClick={()=>{setsousPages("ajoutUrl")}}>Ajouter un Push Mail</button>
         <br/><br/></div>:null}
    <br/><br/>
    <div >
        {setListVideJour(false)}
        {setListVideHeb(false)}
        {setListVideMen(false)}
        {/* className='divUrl'className='bodyUrl' */}
        
        {listVideJour==false && listVideHeb==false && listVideMen==false && urls==[]?<div className="msg">Pas d'URL pour ce site.</div>:null}
       
        {(listVideJour==false || listVideHeb==false || listVideMen==false)?
        <div  className="bodyUrl">
            {urls[0]?UrlsJour():null}
            {urls[0]?UrlsHeb():null}
            {urls[0]?UrlsMens():null}
        </div>:

        <div>
            {urls[0]?UrlsJour():null}
            {urls[0]?UrlsHeb():null}
            {urls[0]?UrlsMens():null}
    </div>}
                 
              
        </div>
        </div>
    )}

  function Url({u,updateUrls}){
    const {site,clientt,token,setUrl,update,setUpdate} = useContext(AppContext)
    const [url,setUrll]=React.useState(u.url)
    const [oldUrl,setOldUrl]=React.useState(u.url)
    const [sending_frequency,setSendingFrequency]=React.useState(u.sending_frequency)
    const [is_active,setActive]=React.useState(u.is_active)
    const [order_,setOrder]=React.useState(u.order_)
    const [page_format,setPageFormat]=React.useState(u.page_format)
    const [auto_mode,setAutoMode]=React.useState(u.auto_mode)
    
    const [change,setChange]=React.useState(false)

    const enregistrer = ()=>{
        setOldUrl(url)
        const urlInfo = {
            url,
            id_site:site.id_site,         
            id_client:clientt.id_client,
            sending_frequency,            
            is_active ,
            oldUrl,
            order_,
            page_format,
            auto_mode
        }
       
        
        postier.updateUrls(token,urlInfo,setUpdate)
        setUrl(urlInfo)
        updateUrls(urlInfo)
        setChange(false)
        // console.log("up",update)
    }
      
      return <div >
        {change?<ChangeUrl 
        u={u} 
        setUrll={setUrll} 
        setSendingFrequency={setSendingFrequency}
        setActive={setActive}
        setOrder={setOrder}
        setAutoMode={setAutoMode}
        setPageFormat={setPageFormat}
        enregistrer={enregistrer} 
        url={url}
        sending_frequency={sending_frequency}
        is_active={is_active} 
        order_={order_}
        auto_mode={auto_mode}
        page_format={page_format}
        />
        :<ShowUrl u={u} setIsModified={setChange} url={url} order_={order_} page_format={page_format} auto_mode={auto_mode}/>}
      </div> 
  }

  function ChangeUrl({u,setUrll,setSendingFrequency,setActive,setOrder,setAutoMode,setPageFormat,enregistrer,url,sending_frequency,is_active,order_,auto_mode,page_format}){
    return<div >
        <table>
            <tr>
                <td><input onChange={e=>{setUrll(e.target.value)}} className="inputUrl"  defaultValue={url}/></td>
                <td> <select className='updateFormat' defaultValue={page_format} onChange={e=>setPageFormat(e.target.value)}>
                {optionsPage.map(opt=>
                <option value={opt} >{opt}</option>
                )}</select></td>
                 <td><button className={auto_mode==1?'updateModeAuto':'updateModeManu'} onClick={()=>{auto_mode==0?setAutoMode(1):setAutoMode(0)}} >
        {auto_mode==0?"Manuel":"Automatique"}
        </button> </td>
                <td> <input onChange={e=>{setOrder(parseInt(e.target.value))}} className="inputOrder"   defaultValue={order_}/></td>
                
               
            </tr>
        </table>  

    <table>
        <tr>
            <td>Fréquence d'envoi</td>
            <td><select className="selectUpdate" defaultValue={sending_frequency} onChange={e=>setSendingFrequency(e.target.value)}>
        
                {options.map(opt=>
                <option value={opt} >{opt}</option>
                )}
            </select></td>
        </tr>
      
    </table>
    
    <button onClick={()=>{is_active==0?setActive(1):setActive(0)}} className='boutonActivationUrl'>
        {is_active==0?"Activer":"Désactiver"}
    </button>

    <button onClick={enregistrer} className='boutonEnregistrer'>Enregistrer</button> 
    
</div> 
  }


  function ShowUrl({u,setIsModified,url,order_,auto_mode,page_format}){
      const{setUpdate,role}= useContext(AppContext)
    function handelClick(){
            setIsModified(true)
            setUpdate(true)
    }

    return<div >
            <table>
                <tr>
                    <td> <input value={url} className='url'/></td>
       <td> <label className='format'>{page_format}</label></td>
       <td><label className={auto_mode==1?'modeAuto':'modeManu'} >{auto_mode==1?"Automatique":"Manuel"}</label></td>
       <td><label  className='page'>{order_}</label> </td>
                    <td>{role=="Admin"?<button className='boutonModifUrl' onClick={handelClick}>
            <img src={iconeModif} alt='iconeModif' className='iconeModif'/>
            </button>:<label className='space'>&emsp;&emsp;</label>}</td>
           
                   
                    
                </tr>
            </table>
                    <div className='separateurUrl'></div>
        
    </div> 
}


function NewUrl({u,setListUrls,index,setUpdateList,sending_frequency,order_}) {
    const optionsPage=["a3","a4"]
    const optionsMode=["Automatique","Manuel"]

     const {clientt,site}= useContext(AppContext)
     const [url,setUrl] =React.useState(u.url)
     const [page_format,setPageFormat] =useState(optionsPage[1])
     const [is_active,setIsActive] =useState(u.is_active)
     const [auto_mode,setAutoMode] =useState(u.auto_mode)


     useEffect(() => {
        setListUrls(listUrls=>{
            let us=listUrls
            us[index]={url,page_format,order_,auto_mode,is_active,sending_frequency,id_client:clientt.id_client,id_site:site.id_site}
            setUpdateList(u=>!u)
            return us
        })

    },[url,page_format,order_,is_active,auto_mode])

    return (<div>
        <input className="inputUrl" value={url} onChange={e=>setUrl(e.target.value)}/>
        
        <select className='formatAdd' defaultValue={page_format} onChange={e=>setPageFormat(e.target.value)}>
        {optionsPage.map(opt=>
                <option value={opt} >{opt}</option>
                )}

        </select>
        <select  className="selectModeAdd"  defaultValue={auto_mode} onChange={e=>{setAutoMode(e.target.value)
               auto_mode=="Automatique"?setAutoMode(1):setAutoMode(0)}}>
                {optionsMode.map(opt=>
                <option value={opt} >{opt}</option>
                )}
                </select>
        <input className="inputOrderAdd" defaultValue={order_} onChange={e=>setOrder(parseInt(e.target.value))}/>
        <br/>
        <label className="labelAdd">Is_active</label>
        <input className="checkbox" type="checkbox" defaultChecked={is_active} onChange={()=>{{
                setIsActive(!is_active)}
                is_active==false?setIsActive(1):setIsActive(0)}}/>
 <div className="separateurUrl"></div>
</div>
       

    )}


    function NewUrlHeb({u,setListUrlsHeb,index,setUpdateListHeb,sending_frequency,order_}) {
        const optionsPage=["a3","a4"]
        const optionsMode=["Automatique","Manuel"]
    
         const {clientt,site}= useContext(AppContext)
         const [url,setUrl] =React.useState(u.url)
         const [page_format,setPageFormat] =useState(optionsPage[1])
         const [is_active,setIsActive] =useState(u.is_active)
         const [auto_mode,setAutoMode] =useState(u.auto_mode)
    
    
         useEffect(() => {
            setListUrlsHeb(listUrlsHeb=>{
                let us=listUrlsHeb
                us[index]={url,page_format,order_,auto_mode,is_active,sending_frequency,id_client:clientt.id_client,id_site:site.id_site}
                setUpdateListHeb(u=>!u)
                return us
            })
    
        },[url,page_format,order_,is_active,auto_mode])
    
        return (<div>
            <input className="inputUrl" value={url} onChange={e=>setUrl(e.target.value)}/>
            
            <select className='formatAdd' defaultValue={page_format} onChange={e=>setPageFormat(e.target.value)}>
            {optionsPage.map(opt=>
                    <option value={opt} >{opt}</option>
                    )}
    
            </select>
            <select  className="selectModeAdd"  defaultValue={auto_mode} onChange={e=>{setAutoMode(e.target.value)
                   auto_mode=="Automatique"?setAutoMode(1):setAutoMode(0)}}>
                    {optionsMode.map(opt=>
                    <option value={opt} >{opt}</option>
                    )}
                    </select>
            <input className="inputOrderAdd" defaultValue={order_} onChange={e=>setOrder(parseInt(e.target.value))}/>
            <br/>
            <label className="labelAdd">Is_active</label>
            <input className="checkbox" type="checkbox" defaultChecked={is_active} onChange={()=>{{
                    setIsActive(!is_active)}
                    is_active==false?setIsActive(1):setIsActive(0)}}/>
    <div className="separateurUrl"></div>
    </div>
           
    
        )}


function NewUrlMen({u,setListUrlsMen,index,setUpdateListMen,sending_frequency,order_}) {
    const optionsPage=["a3","a4"]
    const optionsMode=["Automatique","Manuel"]

     const {clientt,site}= useContext(AppContext)
     const [url,setUrl] =React.useState(u.url)
     const [page_format,setPageFormat] =useState(optionsPage[1])
     const [is_active,setIsActive] =useState(u.is_active)
     const [auto_mode,setAutoMode] =useState(u.auto_mode)


     useEffect(() => {
        setListUrlsMen(listUrlsMen=>{
            let us=listUrlsMen
            us[index]={url,page_format,order_,auto_mode,is_active,sending_frequency,id_client:clientt.id_client,id_site:site.id_site}
            setUpdateListMen(u=>!u)
            return us
        })

    },[url,page_format,order_,is_active,auto_mode])

    return (<div>
        <input className="inputUrl" value={url} onChange={e=>setUrl(e.target.value)}/>
        
        <select className='formatAdd' defaultValue={page_format} onChange={e=>setPageFormat(e.target.value)}>
        {optionsPage.map(opt=>
                <option value={opt} >{opt}</option>
                )}

        </select>
        <select  className="selectModeAdd"  defaultValue={auto_mode} onChange={e=>{setAutoMode(e.target.value)
               auto_mode=="Automatique"?setAutoMode(1):setAutoMode(0)}}>
                {optionsMode.map(opt=>
                <option value={opt} >{opt}</option>
                )}
                </select>
        <input className="inputOrderAdd" defaultValue={order_} onChange={e=>setOrder(parseInt(e.target.value))}/>
        <br/>
        <label className="labelAdd">Is_active</label>
        <input className="checkbox" type="checkbox" defaultChecked={is_active} onChange={()=>{{
                setIsActive(!is_active)}
                is_active==false?setIsActive(1):setIsActive(0)}}/>
<div className="separateurUrl"></div>
</div>
       

    )}



