import React,{useContext} from 'react'
 import './ajoutSite.scss'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'






export function AjoutSite() {
  const {token,setUpdate,clientt,addSite,setsousPages,setSites} = useContext(AppContext)
    const [site_name,setSiteName]=React.useState("")
    const [id_site,setIdSite]=React.useState("")

    const enregistrer = ()=>{
        const siteInfo = {
          site_name,
          id_site,
          id_client:clientt.id_client
      }
      postier.ajoutSite(token,siteInfo,setUpdate)
      addSite(siteInfo)
      setsousPages("urls")
      postier.getSites(token,clientt,setSites)
        
      
    }

    return <div className='ajoutPushMail'>
       
        <NewSite setIdSite={setIdSite} setSiteName={setSiteName}/> 
        <button className="boutonEnregistrer" onClick={enregistrer}>Enregistrer</button>
        <button className="boutonEnregistrer" onClick={()=>setsousPages("urls")}>Annuler</button>
        <br/><br/>
        </div> 


    }

 
function NewSite({setIdSite,setSiteName}){
  return  <table>
    <tr>
      <td><label className="id">Id_site</label></td>
      <td> <input className="inputAdd" onChange={e=>{setIdSite(e.target.value)}}/></td>
      <td> <label className="nom">Nom site</label></td>
      <td> <input className="inputAdd" onChange={e=>{setSiteName(e.target.value)}}/></td>
    </tr>
            
            </table>
    }