import React,{useContext} from 'react'
import pdf from '/assets/PDF_file_icon.svg'
import loading from '/assets/loading.gif'
import corbeille from '/assets/corbeille.png'
import plus from '/assets/plus.jpg'
import {AppContext} from '../../context/Appcontext'
import {postier} from './postier'
import './generatePushMail.scss'

export function EnvoyerPushMail() {
    const {result,setsousPages,token,pages,infoRapports,setInfoRapports,setQuitter}= useContext(AppContext)
    const [chargement,setChagement]=React.useState(false)
    const [add,setAdd]=React.useState(false)
    const [update,setUpdate]=React.useState(false)

    React.useEffect(() => {
        if(pages=="envoyerPushMail"){
            setInfoRapports(result)
        }
        // console.log({infoRapports})
    })
    
    
    const envoyer = ()=>{
        let i=0
        infoRapports.forEach(res => {
            // setMsg(res.msg)
            const info = {
                listMail:res.listMail,
                filename:res.filename,
                subject:res.subject,
                msg:res.msg,
                fileToAttach:res.fileToAttach
            }
            setChagement(true)
            i++
            postier.envoyer(token,info,setChagement,i,infoRapports)
            
        });
      
    }

    React.useEffect(() => {
        // console.log({res:result.listMail})
    },[update])


    return <div className='ajoutPushMail'>
    

        <div><label className='frequence'>Liste de diffusion</label>
        <button  className='boutonModifUrl' onClick={()=>setAdd(true)}><img src={plus} alt='plus' className='iconeModif'/></button>
        </div>
        {add==true ?<Add setAdd={setAdd}/>:null}
        
            <div>
                {infoRapports[0].listMail.length==0?<label>La liste de diffusion est vide</label>:
                 infoRapports[0].listMail.map((m,i)=><div key={Math.random()}><div class="mailaffiche">{m}
                 <button className="boutonSupp" onClick={()=>{
                     infoRapports.map(r=><div key={Math.random()}>{r.listMail.splice(i,1),
                        setUpdate(!update)}
                     
                 </div>)
                 }}><img src={corbeille} alt='corbeille' className='iconeSupp'/></button></div></div>)}
                
            </div>

           
        <label className='frequence'>Objet</label>
        <div><input id="objet" type="text" className="inputMail" defaultValue={infoRapports[0].subject} onChange={e=>{infoRapports.forEach(res =>{
                res.subject=document.getElementById("objet").value
                
            }) 
        }} /></div>
    <br/>
        <label className='frequence'>Nouveau message</label>
        <div> <textarea id="msg" className="textarea"  rows="10" cols="130"  onChange={e=>{infoRapports.forEach(res =>{
                res.msg=document.getElementById("msg").value
                console.log(res.msg)
            }) 
        }}></textarea></div>
       

        {chargement==true?<img src={loading} alt='loading' className='iconeModif'/>:null}
        <button className="boutonEnregistrer" onClick={envoyer}>Envoyer </button>
        <button className="boutonEnregistrer" onClick={()=>{setsousPages("genererPushMail")
        setQuitter(true)}}>Quitter</button>
        
        <br/>
    </div>

}

function Add({setAdd}){
    const {infoRapports}= useContext(AppContext)
    const [mail,setMail]=React.useState("")

    const ajouter = ()=>{
       infoRapports.forEach(res =>{
           res.listMail.push(mail)
        //    console.log({add:res.listMail})
       })
       setAdd(false)
     }
    
    return  <div>
        <label className='frequence'>Nouveau Mail</label>
        <input className="inputMail" type="text" onChange={e=>setMail(e.target.value)}/>
        <button className='boutonEnregistrerEmail' onClick={ajouter}>Enregistrer</button>
    </div>
}

